import request from "./request"

export const visionTranslate = (file) => {
  let formData = new FormData();
  formData.append("file", file);
  return request.post("/vision/translate", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}