<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="10" sm="8" md="6" lg="4" class="text-center">
        <!--カバー画像-->
        <v-row>
          <v-col cols="12" class="text-center">
            <h2>イメージ翻訳</h2>
            <p>画像を選択して、イメージを翻訳する。</p>
            <v-alert dense border="left" type="warning">
              注意：画像より時間かかるので、少々お待ちください。
            </v-alert>
            <v-card class="mx-auto">
              <template slot="progress">
                <v-progress-linear
                  color="deep-purple"
                  height="10"
                  indeterminate
                ></v-progress-linear>
              </template>

              <v-img contain :src="coverImg"></v-img>
            </v-card>
          </v-col>
        </v-row>
        <!--ファイルアップロード-->
        <v-row justify="center">
          <v-col cols="12" class="text-center">
            <v-btn
              color="primary"
              class="text-none"
              rounded
              depressed
              @click="onUpload"
            >
              <v-icon left> mdi-cloud-upload </v-icon>
              {{ buttonText | ellipsis }}
            </v-btn>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              accept="image/*"
              @change="onFileChanged"
            />
          </v-col>
        </v-row>
        <!--結果領域-->
        <v-row v-if="portraitImg">
          <v-col cols="12" class="text-center">
            <v-card class="mx-auto">
              <v-card-title>検出情報：</v-card-title>
              <v-img contain :src="portraitImg"></v-img>
              <v-card-actions>
                <v-btn
                  color="primary"
                  class="text-none"
                  rounded
                  @click="onDownload"
                >
                  <v-icon left> mdi-cloud-download </v-icon>
                </v-btn>
                <v-btn
                  color="primary"
                  class="text-none"
                  rounded
                  @click="priviewImg"
                >
                  <v-icon left> mdi-image-area </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { visionTranslate } from '@/api/translate'

let canvas = null
let ctx = null
export default {
  data: () => ({
    defaultButtonText: '画像をアップロード',
    selectedFile: null,
    isSelecting: false,
    coverImg: require('../assets/img/bda/portraitImg.png'),
    sampleImgs: [],
    portraitImg: null,
    overlay: false,
  }),
  mounted: function() {
    // 创建canvas
    canvas = document.createElement('canvas')
    ctx = canvas.getContext('2d')
  },
  computed: {
    buttonText() {
      return this.selectedFile ? this.selectedFile.name : this.defaultButtonText
    },
  },
  methods: {
    onUpload() {
      this.isSelecting = true
      this.$refs.uploader.click()
    },
    onDownload() {
      const downloadLink = document.createElement('a')
      document.body.appendChild(downloadLink)

      downloadLink.href = this.portraitImg
      downloadLink.target = '_self'
      downloadLink.download = 'portraitImg'
      downloadLink.click()
      document.body.removeChild(downloadLink)
    },
    priviewImg() {
      // 将Base64字符串转换为Blob
      let binary = atob(this.portraitImg.split(',')[1])
      let array = []
      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i))
      }
      let blob = new Blob([new Uint8Array(array)], { type: 'image/jpeg' })

      // 创建Blob URL
      let url = URL.createObjectURL(blob)

      // 在新标签页中打开URL
      window.open(url)
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0]
      if (this.selectedFile) {
        this.overlay = true
        this.portraitImg = null
        var reader = new FileReader()
        reader.onload = (e) => {
          this.coverImg = e.target.result
        }
        reader.readAsDataURL(this.selectedFile)
        this.isSelecting = false
        console.log(this)
        // visionTranslate
        // this.testData

        visionTranslate(this.selectedFile)
          .then((res) => {
            console.log(res)
            console.log(this)

            const blocks = res.data.blocks
            // 查找blockType: "TEXT"
            const textAnnotations = blocks.filter(
              (block) => block.blockType === 'TEXT'
            )
            this.paintFileToCanvas(this.selectedFile).then(() => {
              this.drawText(textAnnotations)
              this.portraitImg = canvas.toDataURL()
            })
          })
          .finally(() => {
            this.overlay = false
          })
      }
    },
    testData() {
      const promise = new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            success: true,
            code: '',
            message: '',
            data: {
              blocks: [
                {
                  blockType: 'TEXT',
                  boundingBox: {
                    vertices: [
                      {
                        x: 83,
                        y: 237,
                      },
                      {
                        x: 354,
                        y: 18,
                      },
                      {
                        x: 373,
                        y: 41,
                      },
                      {
                        x: 102,
                        y: 260,
                      },
                    ],
                  },
                  paragraphs: [
                    {
                      boundingBox: {
                        vertices: [
                          {
                            x: 83,
                            y: 237,
                          },
                          {
                            x: 354,
                            y: 18,
                          },
                          {
                            x: 373,
                            y: 41,
                          },
                          {
                            x: 102,
                            y: 260,
                          },
                        ],
                      },
                      sourceText: '文字起こし 9つのコツ',
                      targetText: '转录的 9 个技巧',
                    },
                  ],
                },
                {
                  blockType: 'TEXT',
                  boundingBox: {
                    vertices: [
                      {
                        x: 42,
                        y: 358,
                      },
                      {
                        x: 484,
                        y: 2,
                      },
                      {
                        x: 718,
                        y: 292,
                      },
                      {
                        x: 275,
                        y: 648,
                      },
                    ],
                  },
                  paragraphs: [
                    {
                      boundingBox: {
                        vertices: [
                          {
                            x: 68,
                            y: 337,
                          },
                          {
                            x: 233,
                            y: 204,
                          },
                          {
                            x: 248,
                            y: 223,
                          },
                          {
                            x: 83,
                            y: 356,
                          },
                        ],
                      },
                      sourceText: '音声ソフトを使う',
                      targetText: '使用音频软件',
                    },
                    {
                      boundingBox: {
                        vertices: [
                          {
                            x: 71,
                            y: 390,
                          },
                          {
                            x: 298,
                            y: 208,
                          },
                          {
                            x: 313,
                            y: 226,
                          },
                          {
                            x: 86,
                            y: 409,
                          },
                        ],
                      },
                      sourceText: '2フットスイッチを使う',
                      targetText: '2 使用脚踏开关',
                    },
                    {
                      boundingBox: {
                        vertices: [
                          {
                            x: 100,
                            y: 421,
                          },
                          {
                            x: 497,
                            y: 102,
                          },
                          {
                            x: 513,
                            y: 122,
                          },
                          {
                            x: 116,
                            y: 441,
                          },
                        ],
                      },
                      sourceText: '3適切なヘッドホン・イヤホンを用意する',
                      targetText: '3 准备合适的耳机/耳机',
                    },
                    {
                      boundingBox: {
                        vertices: [
                          {
                            x: 122,
                            y: 457,
                          },
                          {
                            x: 564,
                            y: 100,
                          },
                          {
                            x: 582,
                            y: 123,
                          },
                          {
                            x: 140,
                            y: 480,
                          },
                        ],
                      },
                      sourceText: '4 「素起こし｣ 「ケバ取り」 「整文」ができる',
                      targetText:
                        '4 能够进行“启动”、“去除多余内容”和“组织句子”。',
                    },
                    {
                      boundingBox: {
                        vertices: [
                          {
                            x: 152,
                            y: 489,
                          },
                          {
                            x: 403,
                            y: 287,
                          },
                          {
                            x: 420,
                            y: 309,
                          },
                          {
                            x: 170,
                            y: 511,
                          },
                        ],
                      },
                      sourceText: '5表記の知識を身につける',
                      targetText: '5 掌握符号知识',
                    },
                    {
                      boundingBox: {
                        vertices: [
                          {
                            x: 179,
                            y: 524,
                          },
                          {
                            x: 432,
                            y: 320,
                          },
                          {
                            x: 476,
                            y: 374,
                          },
                          {
                            x: 222,
                            y: 578,
                          },
                        ],
                      },
                      sourceText:
                        '6 専門用語を知っておく7不明箇所は対処して進む',
                      targetText: '6 了解技术术语 7 处理不清楚的问题并继续',
                    },
                    {
                      boundingBox: {
                        vertices: [
                          {
                            x: 233,
                            y: 593,
                          },
                          {
                            x: 653,
                            y: 254,
                          },
                          {
                            x: 697,
                            y: 308,
                          },
                          {
                            x: 276,
                            y: 647,
                          },
                        ],
                      },
                      sourceText:
                        '8 音声データの状況別のポイントを押さえる9 頻出単語は省力タイピング',
                      targetText:
                        '8 了解每种语音数据情况的要点 9 省力地输入频繁出现的单词',
                    },
                  ],
                },
              ],
            },
          })
        }, 2000)
      })
      return promise
    },
    // 将上传的图片画到canvas上
    paintFileToCanvas(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = (e) => {
          // 清空画布
          ctx.clearRect(0, 0, canvas.width, canvas.height)
          // 画图片
          const img = new Image()
          img.onload = () => {
            // 图片最长边为1920
            const max = 1920
            let width = img.width
            let height = img.height
            if (width > height) {
              if (width > max) {
                height = (height * max) / width
                width = max
              }
            } else {
              if (height > max) {
                width = (width * max) / height
                height = max
              }
            }
            canvas.width = width
            canvas.height = height
            ctx.drawImage(img, 0, 0, width, height)
            resolve()
          }
          img.onerror = () => {
            reject(new Error('画布绘制失败'))
          }
          img.src = e.target.result
        }
        reader.onerror = () => {
          reject(new Error('文件读取失败'))
        }
        reader.readAsDataURL(file)
      })
    },
    drawText(textAnnotations) {
      textAnnotations.forEach((text) => {
        const paragraphs = text.paragraphs
        // paragraphs 包含的boundingBox 是一个对象
        // vertices 是上下左右四个点的坐标
        // 顺序是左上，右上，右下，左下
        // targetText 是文字内容
        // 根据vertices画一个矩形
        paragraphs.forEach((paragraph) => {
          const vertices = paragraph.boundingBox.vertices
          const targetText = paragraph.targetText
          const x1 = vertices[0].x
          const y1 = vertices[0].y
          const x2 = vertices[1].x
          const y2 = vertices[1].y
          const x3 = vertices[2].x
          const y3 = vertices[2].y
          const x4 = vertices[3].x
          const y4 = vertices[3].y

          ctx.beginPath()
          ctx.moveTo(x1, y1)
          ctx.lineTo(x2, y2)
          ctx.lineTo(x3, y3)
          ctx.lineTo(x4, y4)
          ctx.closePath()
          ctx.lineWidth = 2
          // 橙色
          ctx.strokeStyle = '#ff9800'
          ctx.stroke()

          // 填充矩形
          ctx.fillStyle = '#ff9800' // 设置填充颜色
          ctx.fill() // 填充矩形
          // 计算矩形的宽度和高度
          let rectWidth = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2))
          let rectHeight = Math.sqrt(
            Math.pow(x3 - x2, 2) + Math.pow(y3 - y2, 2)
          )

          // 开始写字
          // 如果高度大于宽度两倍，说明文字是竖着的
          if (rectHeight > rectWidth * 2) {
            this.writeVerticalText(targetText, x1, y1, x2, y2, x3, y3, x4, y4)
          } else {
            this.writeHorizontalText(targetText, x1, y1, x2, y2, x3, y3, x4, y4)
          }
        })
      })
    },
    // eslint-disable-next-line no-unused-vars
    writeVerticalText(targetText, x1, y1, x2, y2, x3, y3, x4, y4) {
      // 计算矩形的中心点坐标
      const centerX = (x1 + x2 + x3 + x4) / 4
      const centerY = (y1 + y2 + y3 + y4) / 4

      // 根据四个点的坐标计算倾斜角度（弧度）
      const angleInRadians = Math.atan2(y2 - y1, x2 - x1)

      // 旋转画布
      ctx.translate(centerX, centerY)
      ctx.rotate(angleInRadians)
      ctx.translate(-centerX, -centerY)

      // 计算矩形的宽度和高度
      let rectWidth = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2))
      let rectHeight = Math.sqrt(Math.pow(x3 - x2, 2) + Math.pow(y3 - y2, 2))

      // 设置字体大小
      let fontSize = rectHeight / targetText.length - 1
      if (fontSize > rectWidth) {
        fontSize = rectWidth - 1
      }
      ctx.font = `${fontSize}px sans-serif`

      // 设置文本对齐方式
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      ctx.fillStyle = '#ffffff'

      // 绘制每个字符
      for (let i = 0; i < targetText.length; i++) {
        let char = targetText[i]
        let y = centerY - rectHeight / 2 + fontSize * (i + 0.5)
        ctx.fillText(char, centerX, y)
      }

      // 恢复Canvas的初始状态
      ctx.setTransform(1, 0, 0, 1, 0, 0)
    },

    writeHorizontalText(targetText, x1, y1, x2, y2, x3, y3, x4, y4) {
      // 计算矩形的中心点坐标
      const centerX = (x1 + x2 + x3 + x4) / 4
      const centerY = (y1 + y2 + y3 + y4) / 4

      // 根据四个点的坐标计算倾斜角度（弧度）
      const angleInRadians = Math.atan2(y2 - y1, x2 - x1)

      // 旋转画布
      ctx.translate(centerX, centerY)
      ctx.rotate(angleInRadians)
      ctx.translate(-centerX, -centerY)

      // 计算矩形的宽度和高度
      let rectWidth = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2))
      let rectHeight = Math.sqrt(Math.pow(x3 - x2, 2) + Math.pow(y3 - y2, 2))

      this.fitTextOnCanvas(targetText, rectWidth, rectHeight, centerX, centerY)

      // 恢复Canvas的初始状态
      ctx.setTransform(1, 0, 0, 1, 0, 0)
    },
    wrapText(context, text, x, y, maxWidth, lineHeight, rectHeight) {
      var lines = []

      // 先测量整个文本的宽度
      var metrics = context.measureText(text)
      var textWidth = metrics.width

      if (textWidth <= maxWidth) {
        // 如果整个文本的宽度小于最大宽度，那么不需要分行
        lines.push({ text: text, x: x, y: y })
      } else {
        // 如果整个文本的宽度大于最大宽度，那么需要分行
        var line = ''
        for (var n = 0; n < text.length; n++) {
          var testLine = line + text[n]
          metrics = context.measureText(testLine)
          var testWidth = metrics.width
          if (testWidth > maxWidth && n > 0) {
            lines.push({ text: line, x: x, y: y })
            if ((lines.length + 1) * lineHeight > rectHeight) break // 检查是否溢出
            line = text[n]
            y += lineHeight
          } else {
            line = testLine
          }
        }
        lines.push({ text: line, x: x, y: y }) // 添加最后一行
      }

      // 如果文本在垂直空间内适应，则绘制
      if (lines.length * lineHeight <= rectHeight) {
        lines.forEach((lineObj) => {
          context.fillText(lineObj.text, lineObj.x, lineObj.y)
        })
        return true // 成功填充矩形
      }

      return false // 文本会溢出
    },
    fitTextOnCanvas(text, rectWidth, rectHeight, centerX, centerY) {
      // 获取canvas和上下文
      const context = ctx

      // 从大号字体开始快速估计
      var fontSize = 80
      context.font = fontSize + 'px Arial'

      // 减小字体大小直至文本适应宽度或高度
      var fits = false
      while (!fits && fontSize > 0) {
        context.textBaseline = 'top'
        context.fillStyle = '#ffffff'
        context.font = fontSize + 'px Arial'
        var lineHeight = context.measureText('国').width * 1.2 // 粗略估计行高
        const withOffset = Math.max(rectWidth * 0.01, 3)
        const heightOffset = Math.max(rectHeight * 0.03, 3)
        fits = this.wrapText(
          context,
          text,
          centerX - rectWidth / 2 + withOffset,
          centerY - rectHeight / 2 + heightOffset,
          rectWidth - withOffset,
          lineHeight,
          rectHeight - heightOffset
        )
        if (!fits) {
          fontSize--
        }
      }

      // 如果文本在任何大小下都不适合，则适当处理
      if (fontSize === 0) {
        console.error('文本无法适应给定的矩形。')
      }
    },
  },

  filters: {
    ellipsis(value) {
      let len = value.length
      if (!value) return ''
      if (value.length > 20) {
        return value.substring(0, 8) + '...' + value.substring(len - 8, len)
      }
      return value
    },
  },
}
</script>

<style>
.v-icon--left {
  margin-right: 8px;
}
.dager {
  background: #f44336;
}
.waring {
  background: #ff9800;
}
</style>
